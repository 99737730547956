<template>
    <div class="wrapper-registro cover-login">
        <div class="popup -full">
            <ValidationObserver ref="form" v-slot="{ failed, valid }">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="new-text">
                    <h2 class="titulo left">Formulario de registro Alumni, Elumni & Friends</h2>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="nombre" placeholder="Nombre*" v-model="data.name" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="apellido-uno" placeholder="Apellido 1*" v-model="data.surname" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="campo-texto">

                        <div class="contenedor-input half">

                            <input autocomplete="new-password" type="text" name="apellido-dos" placeholder="Apellido 2" v-model="data.surname_2">

                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <select id="nacionalidad" name="nacionalidad" v-model="data.nationality" class="pretty" :class="classes">
                                    <option name="myBrowser" disabled placeholder="" value="">Nacionalidad*</option>
                                    <option :key="key" v-for="(pais, key) in dominios.paises" name="myBrowser" :value="pais">
                                        {{ pais }}</option>
                                </select>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <select id="tipodocumento" name="tipodocumento" v-model="data.document_type" class="pretty" :class="classes">
                                <option name="myBrowser" placeholder="" disabled value="">Tipo de documento*</option>
                                <option :key="key" v-for="(tipo, key) in dominios.tiposdocumento" name="myBrowser" :value="key">
                                    {{ tipo }}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider :rules="(data.document_type != 1) ? 'required|validdni' : 'required'" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="dni" :placeholder="(data.document_type) ? dominios.tiposdocumento[data.document_type] : 'Documento*'" v-model="data.document" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="campo-texto">
                        <div class="contenedor-input half">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <input autocomplete="new-password" type="email" name="mail" placeholder="Mail*" v-model="data.email" :class="classes">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input half movil">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <input autocomplete="new-password" type="text" name="movil" placeholder="Móvil*" v-model="data.phone" :class="classes">
                                <p class="msg-info-tel">Por favor no utilices ni espacios ni símbolos, si tienes un teléfono
                                    extranjero indica el
                                    prefijo con 00 en vez de con +</p>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="contenedor-input half row-start fww pt0">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <h5>Sexo*</h5>
                            <input type="radio" :value="'0'" name="sexo" id="hombre" v-model="data.gender">
                            <label :class="classes" for="hombre">Hombre</label>
                            <input type="radio" :value="'1'" name="sexo" id="mujer" v-model="data.gender">
                            <label :class="classes" for="mujer">Mujer</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half pt0">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <date-picker v-model="data.birthday_date" :format="'DD/MM/YYYY'" :value-type="'YYYY/MM/DD'" :class="classes" :placeholder="'Fecha de nacimiento*'"></date-picker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <select id="paisresidencia" name="paisresidencia" v-model="data.country" class="pretty" :class="classes">
                                <option name="myBrowser" placeholder="" disabled value="">País de residencia*</option>
                                <option :key="key" v-for="(pais, key) in dominios.paises" name="myBrowser" :value="pais">
                                    {{ pais }}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half" v-if="data.country != 'España'">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="provincia" placeholder="Ciudad/Provincia*" v-model="data.province" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half" v-show="data.country == 'España'">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <select id="provincia" name="provincia" v-model="data.province" class="pretty" :class="classes">
                                <option placeholder="" value="">Provincia*</option>
                                <option :key="key" v-for="(provincia, key) in dominios.provincias" :value="provincia">
                                    {{ provincia }}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="direction" placeholder="Dirección*" v-model="data.address" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required|min:5" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="cp" placeholder="Código Postal*" v-model="data.cp" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input half">
                        <ValidationProvider :rules="'required|min:8'" v-slot="{ errors, ariaInput, ariaMsg, classes }" vid="password">
                            <input autocomplete="new-password" type="password" name="pass" placeholder="Contraseña*" v-model="data.password" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input half">
                        <ValidationProvider :rules="'required|confirmed:password|min:8'" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="password" name="repite-pass" placeholder="Repite tu contraseña*" v-model="data.password_confirmation" :class="classes">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input row-start fww">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <h5>Situación laboral*</h5>
                            <input type="radio" :value="'0'" name="situacion" id="paro" v-model="data.working">
                            <label :class="classes" for="paro">En paro</label>
                            <input type="radio" :value="'1'" name="situacion" id="activo" v-model="data.working">
                            <label :class="classes" for="activo">Activo</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <template v-if="data.working == '1'">

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|max:50" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <input autocomplete="new-password" type="text" name="puesto" placeholder="Puesto*" v-model="data.profesional[0].position" :class="classes" max="50">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required|max:50" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <input autocomplete="new-password" type="text" name="empresa" placeholder="Empresa*" v-model="data.profesional[0].company" :class="classes" max="50">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input half">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <date-picker :name="'fecha'" v-model="data.profesional[0].start_date" :format="'DD/MM/YYYY'" :value-type="'YYYY/MM/DD'" :class="classes" :placeholder="'Fecha de Inicio*'"></date-picker>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input half">
                            <div class="toggle">
                                <input type="checkbox" id="actual" v-model="data.profesional[0].actual">
                                <label for="actual">Actualmente</label>
                            </div>
                            <date-picker v-if="!data.profesional[0].actual" :name="'fecha'" v-model="data.profesional[0].end_date" :format="'DD/MM/YYYY'" :value-type="'YYYY/MM/DD'" :class="classes" :placeholder="'Fecha de Fin'"></date-picker>
                        </div>


                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <autocomplete :class="classes" :name="'sector'" :label="'Sector*'" :type="'sector'" v-model="data.profesional[0].sector" :idkey="'id'" :formtype="'select'"></autocomplete>


                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                    </template>

                    <h2 class="titulo left">Indícanos cuál es tu relación con la UFV*</h2>


                    <span class="custom-error -relacion" v-if="clicked && !bowl">Debes marcar al menos una relación con
                        la UFV</span>

                    <div class="toggle" :error="clicked && !bowl">
                        <input type="checkbox" id="elu" v-model="data.egresado">
                        <label for="elu">Soy Antiguo Alumno UFV (incluido ELU)</label>
                    </div>


                    <template v-if="data.egresado">

                        <p><strong>Datos académicos.</strong></p>

                        <div class="contenedor-input row-start only-one-option no-margin">



                            <div class="contenedor-input sinmargen more-options row-start fww ">
                                <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                    <h5>Soy Egresado de la Escuela de Liderazgo Universitario (ELU)*</h5>
                                    <div>
                                        <input type="radio" :value="true" name="haselu" id="si" v-model="data.study[0].haselu">
                                        <label :class="classes" for="si">Sí</label>
                                        <input type="radio" :value="false" name="haselu" id="no" v-model="data.study[0].haselu">
                                        <label :class="classes" for="no">No</label>
                                    </div>
                                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>


                        <div class="contenedor-input " v-if="data.study[0].haselu">
                            <p class="msg-info-tel">Por favor, indica a continuación los datos de la titulación
                                <strong><u>que cursabas cuando participaste</u></strong> en la ELU. Si eres egresado/a
                                de más de una titulación, podrás incluirla desde tu perfil una vez te hayas registrado.
                            </p>
                            <div class="grupo-form">
                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <select id="nivel" name="nivel" v-model="data.study[0].level" class="pretty" :class="classes">
                                            <option name="myBrowser" disabled placeholder="" value="">Nivel*</option>
                                            <option :key="key" v-for="(nivel, key) in dominios.nivel" name="myBrowser" :value="key">
                                                {{ nivel }}</option>
                                        </select>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <autocomplete :formtype="'select'" :class="classes" :name="'study'" :label="'Titulación*'" :type="'study'" v-model="data.study[0].study" :idkey="'id'"></autocomplete>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                            </div>
                            <div class="grupo-form">
                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <autocomplete :class="classes" :formtype="'select'" :name="'university'" :label="'Universidad*'" :type="(data.study[0].haselu) ? 'university' : 'ufv'" v-model="data.study[0].university" :idkey="'id'" ref="university" :notfill="true">
                                        </autocomplete>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>



                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <year-datepicker :class="classes" :label="'Año de promoción*'" :minpage="new Date()" v-model="data.study[0].year" :name="'year'">
                                        </year-datepicker>

                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <year-datepicker :class="classes" :label="'Año de promoción ELU*'" :minpage="new Date()" v-model="data.study[0].elu_year" :name="'elu_year'">
                                </year-datepicker>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                            <p class="msg-info-tel">Es el año en el que finalizaste tus estudios ELU</p>
                        </div>


                        <div class="contenedor-input " v-else>
                            <p class="msg-info-tel espacio">Por favor, indica a continuación los datos de la titulación
                                <strong><u>que has cursado</u></strong> en la UFV. Si eres egresado/a de más de una
                                titulación, podrás incluirla desde tu perfil una vez te hayas registrado.
                            </p>

                            <div class="grupo-form">
                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <select id="nivel" name="nivel" v-model="data.study[0].level" class="pretty" :class="classes">
                                            <option name="myBrowser" disabled placeholder="" value="">Nivel*</option>
                                            <option :key="key" v-for="(nivel, key) in dominios.nivel" name="myBrowser" :value="key">
                                                {{ nivel }}</option>
                                        </select>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>


                                </div>
                             

                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <autocomplete :formtype="'select'" :class="classes" :name="'study'" :label="'Titulación*'" :type="'study'" v-model="data.study[0].study" :idkey="'id'"></autocomplete>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                            </div>

                            <div class="grupo-form">
                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <autocomplete :class="classes" :formtype="'select'" :name="'university'" :label="'Universidad*'" :type="(data.study[0].haselu) ? 'university' : 'ufv'" v-model="data.study[0].university" :idkey="'id'" ref="university" :notfill="true">
                                        </autocomplete>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="contenedor-input half">
                                    <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                        <year-datepicker :class="classes" :label="'Año de promoción*'" :minpage="new Date()" v-model="data.study[0].year" :name="'year'">
                                        </year-datepicker>
                                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>

                            </div>
                        </div>




                    </template>

                    <div class="toggle" :error="clicked && !bowl">
                        <input type="checkbox" id="familiar" v-model="data.familiar_bowl">
                        <label for="familiar">Soy padre o madre de un alumno de la UFV</label>
                    </div>

                    <template v-if="data.familiar_bowl">


                        <p><strong>Datos Familiares y amigos.</strong></p>

                        <template v-for="(family, key) in data.family">

                            <div class="contenedor-input" :key="'personal_type' + key" v-if="key == 0">


                                <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                    <autocomplete :formtype="'select'" :class="classes" :name="'personal_type'" :label="'Relación*'" :type="'personal_type'" v-model="family.type" :idkey="'id'"></autocomplete>
                                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>


                            <div class="contenedor-input" v-if="family.type == 4" :key="'nombre' + key">
                                <ValidationProvider rules="required|max:50" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                    <input autocomplete="new-password" type="text" name="nombre" placeholder="Nombre de tu hijo/a*" v-model="family.name" :class="classes" max="50">
                                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>



                            <div class="contenedor-input" v-if="family.type == 4" :key="'year' + key">
                                <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                    <year-datepicker :class="classes" :label="'Año de ingreso*'" :minpage="new Date()" v-model="family.ufv_year" :name="'year' + key">
                                    </year-datepicker>
                                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>

                            <div class="contenedor-input" v-if="family.type == 4" :key="'study' + key">
                                <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                    <autocomplete :class="classes" :name="'study' + key" :label="'Estudios que realiza en la UFV*'" :type="'study'" v-model="family.studies[0]" :idkey="'id'"></autocomplete>
                                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>

                            <div class="contenedor-input" :key="key" v-if="family.type != 4">
                                <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                    <textarea name="comment" id="comment" v-model="family.comment" :fill="!!family.comment" :class="classes" placeholder="Indica el nombre y apellidos de tu familiar o amigo en la UFV*"></textarea>
                                    <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>


                            <a class="link -red" v-if="key != 0" :key="key" @click="remove(key)">Eliminar</a>


                        </template>

                        <a class="link" v-if="data.family.some(e => e.type == 4)" @click="morechilds">Añadir más
                            hijos</a>

                    </template>

                    <div class="toggle" :error="clicked && !bowl">
                        <input type="checkbox" id="estudiante-internacional" v-model="data.internacional_bowl">
                        <label for="estudiante-internacional">He sido estudiante erasmus en la UFV / I have been an
                            erasmus student at UFV</label>
                    </div>

                    <template v-if="data.internacional_bowl">

                        <p><strong>Datos Alumni Internacional.</strong></p>

                        <div class="contenedor-input row-start fww">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <h5>¿Cuál es tu relación con la UFV?*</h5>
                                <div class="full" v-for="(n, key) in dominios.relacionufv" :key="key">
                                    <input type="radio" :value="key" name="international_relation" :id="'relacion-' + key" v-model="data.international_relation" :class="classes">
                                    <label :for="'relacion-' + key">{{ n }}</label>
                                </div>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <year-datepicker :class="classes" :label="'¿Cuándo participaste o hiciste tu intercambio con la UFV?*'" :minpage="new Date()" v-model="data.international_year" :name="'international_year'">
                                </year-datepicker>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <input autocomplete="new-password" type="text" name="buddy" placeholder="Indícanos el nombre de tu buddy en la UFV*" v-model="data.internaltional_buddy" :class="classes">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </template>

                    <div class="toggle" :error="clicked && !bowl">
                        <input type="checkbox" id="antiguo-empleado" v-model="data.last_ufv_check">
                        <label for="antiguo-empleado">Soy antiguo empleado de UFV</label>
                    </div>

                    <div class="contenedor-input" v-if="data.last_ufv_check">
                        <ValidationProvider rules="required|max:50" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input autocomplete="new-password" type="text" name="last_ufv" placeholder="Indica tu último puesto en la UFV*" v-model="data.last_ufv" :class="classes" max="50">
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="text_politica">
                        <p>El responsable del tratamiento de sus datos es la Universidad Francisco de Vitoria (UFV).</p>
                        <p>La finalidad del tratamiento es</p>
                        <div style="padding: 0 15px;">
                            <ul>
                                <li>Enviarle, por cualquier medio, incluidos los electrónicos (SMS, Whatsapp, correo
                                    electrónico y redes sociales), comunicaciones relacionadas con empleabilidad,
                                    observatorio de empleo, ofertas, promociones, productos, actividades, concursos y
                                    servicios de la Universidad Francisco de Vitoria y/o patrocinados por la misma.</li>
                                <li>Gestionar su inscripción en las actividades organizadas por la Universidad Francisco
                                    de Vitoria (UFV).</li>
                                <li>Publicar videos e imágenes en las diferentes páginas webs de la universidad y redes
                                    sociales en las que pueda aparecer a raíz de su participación en las diferentes
                                    actividades de la universidad (conferencias, concursos, etc.). En el caso de que se
                                    le invitara a participar en actuaciones específicas de promoción de la Universidad
                                    Francisco de Vitoria, se le pediría su consentimiento documentalmente si su imagen
                                    tuviera un carácter relevante.</li>
                            </ul>

                        </div>

                        <p>
                            La legitimación del tratamiento se basa en el consentimiento del interesado marcando la
                            casilla destinada a tal efecto, y el interés legítimo del responsable, según se detalla en
                            la información ampliada. Los datos se mantendrán mientras usted no revoque el consentimiento
                            otorgado o no se oponga al tratamiento realizado a través del link:<a href="https://www.alumni-ufv.es/es/general/solicitar-baja" target="_blank">https://www.alumni-ufv.es/es/general/solicitar-baja</a></p>
                        <p>La información facilitada no será comunicada a terceros ajenos a la UFV, salvo a prestadores
                            de servicio con acceso a datos. </p>
                        <p>Puede ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación del
                            tratamiento y portabilidad mediante un escrito dirigido a la Secretaría General de la
                            Universidad Francisco de Vitoria, Ctra. M-515 Pozuelo-Majadahonda Km. 1,800; 28223, Pozuelo
                            de Alarcón (Madrid), o al correo electrónico <a href="mailto:dpd@ufv.es.">dpd@ufv.es.</a>
                        </p>
                        <p>
                            Si a través del presente formulario facilita datos de terceras personas, le informamos de
                            que queda obligado a informar al interesado sobre el contenido de esta cláusula.</p>

                        <p>
                            Puede consultar la información ampliada en la web <a href="https://www.alumni-ufv.es/es/general/politica-privacidad" target="_blank">https://www.alumni-ufv.es/es/general/politica-privacidad</a>
                        </p>


                    </div>

                    <div class="contenedor-input">
                        <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                            <input type="checkbox" id="contact" v-model="data.contact" :class="classes">
                            <label for="contact">Acepto y autorizo a que mis datos sean tratados por la Universidad
                                Francisco
                                de
                                Vitoria, con las finalidades indicadas anteriormente y para remitirme, por cualquier
                                medio,
                                incluidos los electrónicos (SMS, Whatsapp, correo electrónico y redes sociales) las
                                comunicaciones correspondientes.</label>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Enviar</button>
                    </div>


                </form>
            </ValidationObserver>
        </div>
        <div class="contenedor-popup" v-if="showpopup">
            <div class="popup">
                <p>Si has marcado este botón es porque has cursado un grado, un postgrado o la ELU en la UFV.</p>
                <a @click="showpopup = false" class="boton-azul">Aceptar</a>
            </div>
        </div>

    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    import router from '@/router';

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es/es';

    export default {
        name: 'registro',
        data: () => ({

            data: {

                'document': '',
                'document_type': '',
                'email': '',
                'password': '',
                'password_confirmation': '',
                'name': '',
                'surname': '',
                'surname_2': '',
                'phone': '',
                'birthday_date': '',
                'gender': '',
                'nationality': '',
                'country': '',
                'province': '',
                'contact': '',
                'familiar_bowl': false,
                'egresado': false,
                'working': '',
                'last_ufv': '',
                'last_ufv_check': false,
                'club_senior': false,
                'family': [
                    {
                        "type": '',
                        "comment": '',
                        "dni": '',
                        "name": '',
                        "studies": []
                    }
                ],
                'study': [
                    {
                        university: null,
                        study: '',
                        level: '',
                        elu_year: '',
                        year: '',
                        haselu: false
                    }
                ],
                profesional: [
                    {
                        company: '',
                        position: '',
                        start_date: '',
                        end_date: '',
                        sector: '',
                        actual: true
                    }
                ]
            },
            tips: {
                'familiar': false,
                'internacional': false,
            },
            loading: false,
            bowl: false,
            clicked: false,
            showpopup: false
        }),
        components: { DatePicker },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['createAlumni', 'setAlert']),
            validate() {

                this.clicked = true;

                this.checkRelaciones();

                this.$refs.form.validate().then(success => {

                    if (success && this.bowl) {

                        this.loading = true;

                        if (!this.data.familiar_bowl) {
                            delete this.data.family;
                        }

                        if (!this.data.egresado) {
                            delete this.data.study;
                        }

                        if (this.data.family && this.data.family.length != 0) {
                            this.data.family.forEach(e => {
                                if (!e.comment) {
                                    e.comment = e.name;
                                }
                            })
                        }

                        if (this.data.working == "0") {
                            delete this.data.profesional;
                        }

                        this.createAlumni(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                router.push({
                                    name: 'login'
                                })
                            }
                        })
                    } else {
                        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });

                    }
                });
            },
            checkRelaciones() {
                let required = ['internacional_bowl', 'egresado', 'familiar_bowl', 'last_ufv_check'];
                this.bowl = false;
                required.forEach(e => {
                    if (this.data[e] === true) {
                        this.bowl = true;
                    }
                });
            },
            morechilds() {
                let family = {
                    "type": '4',
                    "comment": '',
                    "dni": '',
                    "name": '',
                    "studies": []
                }

                this.data.family.push(family);
            },
            remove(pos) {
                this.data.family = this.data.family.splice(pos, 1);
            }
        },
        watch: {
            'data.study[0].haselu': {
                deep: true,


                handler() {
                    setTimeout(() => {
                        this.$refs.university.requestStudy();
                    }, 500)

                }

            },
            'data': {
                deep: true,
                handler() {
                    this.checkRelaciones();
                }
            },
            'data.egresado'() {
                if (this.data.egresado) {
                    this.showpopup = true;
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
    .link {
        margin-bottom: 20px;
        text-decoration: underline;

        &.-red {
            color: red;
        }
    }

    .sinmargen {
        padding-bottom: 0px;
    }

    .contenedor-popup {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        z-index: 50;
        background: rgba(0, 0, 0, .6);

        .popup {
            max-width: 400px;

            p {
                margin-bottom: 20px;
            }
        }
    }

    .no-margin {
        margin-bottom: 0px;
        padding-bottom: 0px;
        padding-top: 0px;

        .contenedor-input {
            margin-bottom: 0px;
        }
    }

    .no-margin {
        margin-bottom: 0px;
        padding-bottom: 0px;

        .contenedor-input {
            margin-bottom: 0px;
        }
    }
</style>